<template>
  <b-overlay :show="formShow">
    <b-row class="invoice-preview">
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card table-responsive">
          <span style="padding:10px;">
            <strong>Lead Number :</strong> #{{ Number(leadData.id) }} <strong> Lead Date :</strong> {{
                dateFormat(leadData.created_at)
            }}
            <span v-if="leadData.userId"> <strong> User :</strong> {{ users.find((x) => x.userId == leadData.userId) ?
                users.find((x) => x.userId == leadData.userId).value : 'All Users'
            }} </span>
            <span v-if="leadData.channel"> <strong> Channel :</strong> {{ leadData.channel }} </span>

          </span>

          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Lead Details</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue" v-if="leadData.source == 'website' || leadData.source == 'WEBSITE'">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="leadData.source == 'website' || leadData.source == 'WEBSITE'">
                    &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Website
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="leadData.source == 'user' || leadData.source == 'USER'">
                    &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  User
                </td>
                <td colspan="4">{{ leadData.firstName }} &nbsp;</td>
                <td colspan="4">{{ leadData.lastName }} &nbsp; </td>
              </tr>
              <tr class="textvalue" v-if="leadData.source == 'user' || leadData.source == 'USER'">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="leadData.source == 'website' || leadData.source == 'WEBSITE'">
                    &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Website
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="leadData.source == 'user' || leadData.source == 'USER'">
                    &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  User
                </td>
                <td colspan="4">{{ leadData.firstName }}</td>
                <td colspan="4">{{ leadData.lastName }}</td>
              </tr>
              <tr class="title">
                <th colspan="4" class="noborderright">Lead Source</th>
                <th colspan="4" class="noborderright">First Name / Business Name</th>
                <th colspan="4">Last Name / Operating Name</th>
              </tr>

              <tr class="textvalue" v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                <td colspan="4">{{ leadData.driversLicense }} &nbsp;</td>
                <td colspan="4">{{ leadData.expiration }} &nbsp;</td>
                <td colspan="2">{{ leadData.dateOfBirth }} &nbsp;</td>
                <td colspan="2">{{ leadData.socialSecurity }} &nbsp;</td>

              </tr>

              <tr class="title" v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                <th colspan="4" class="noborderright">Driver's License Number</th>
                <th colspan="4" class="noborderright">Expiration Date</th>
                <th colspan="2" class="noborderright">Date of Birth</th>
                <th colspan="2">Social Security Number</th>

              </tr>




              <tr class="textvalue">
                <td colspan="4">{{ leadData.method }} &nbsp;</td>
                <td colspan="4">{{ leadData.phone }} &nbsp;</td>
                <td colspan="4">{{ leadData.email }} &nbsp;</td>
              </tr>

              <tr class="title">
                <th colspan="4" class="noborderright">Preferred Contact Method</th>
                <th colspan="4" class="noborderright">Phone Number</th>
                <th colspan="4">Email</th>
              </tr>



              <tr class="textvalue">
                <td colspan="4">{{ leadData.reason ? leadData.reason : '-' }}</td>
                <td colspan="4">{{ leadData.solution ? leadData.solution : '-' }}</td>
                <td colspan="2">{{ leadData.quote ? leadData.quote : '-' }}</td>
                <td colspan="2">{{ leadData.stockNumber ? leadData.stockNumber : '-' }}</td>
              </tr>

              <tr class="title">
                <th colspan="4">Reason</th>
                <th colspan="4">Solution</th>
                <th colspan="2">Quote</th>
                <th colspan="2" class="noborderright">Stock Number</th>
              </tr>
            </tbody>


          </table>

          <table class="table table-bordered" v-if="leadData.reason == 'TO LEASE A VEHICLE'">

            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Residence Information</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue">
                <td colspan="12">{{ leadData.address ? leadData.address : '' }} &nbsp;</td>

              </tr>

              <tr class="title">
                <th colspan="12" class="noborderright">Address</th>

              </tr>

              <tr class="textvalue">
                <td colspan="4">{{ leadData.city ? leadData.city : '&nbsp; ' }} </td>
                <td colspan="4">{{ leadData.state ? leadData.state : '&nbsp; ' }}</td>
                <td colspan="4">{{ leadData.zip ? leadData.zip : '&nbsp; ' }}</td>
              </tr>

              <tr class="title">
                <th colspan="4" class="noborderright">City</th>
                <th colspan="4" class="noborderright">State</th>
                <th colspan="4" class="noborderright">Zip</th>
              </tr>


              <tr class="textvalue">
                <td colspan="4">{{ leadData.addressYear ? leadData.addressYear : '&nbsp; ' }}</td>
                <td colspan="4">{{ leadData.addressMonth ? leadData.addressMonth : '&nbsp; ' }}</td>
                <td colspan="2">{{ leadData.residenceType ? leadData.residenceType : '&nbsp; ' }}</td>
                <td colspan="2">{{ leadData.monthlyPayment ? leadData.monthlyPayment : '&nbsp; ' }}</td>

              </tr>

              <tr class="title">
                <th colspan="4" class="noborderright">Time of Address Years</th>
                <th colspan="4" class="noborderright">Months</th>
                <th colspan="2" class="noborderright">Residence Type</th>
                <th colspan="2" class="noborderright">Monthly Payment</th>

              </tr>

            </tbody>


          </table>

          <table class="table table-bordered" v-if="leadData.reason == 'TO LEASE A VEHICLE'">

            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Employment Information</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue">
                <td colspan="4">{{ leadData.employer ? leadData.employer : '&nbsp; ' }}</td>
                <td colspan="4">{{ leadData.officeAddress ? leadData.officeAddress : '&nbsp; ' }}</td>
                <td colspan="4">{{ leadData.officeCity ? leadData.officeCity : '&nbsp; ' }}</td>
              </tr>

              <tr class="title">
                <th colspan="4" class="noborderright">Employer</th>
                <th colspan="4" class="noborderright">Address</th>
                <th colspan="4" class="noborderright">City</th>
              </tr>

              <tr class="textvalue">
                <td colspan="4">{{ leadData.officeState ? leadData.officeState : '&nbsp; ' }}</td>
                <td colspan="4">{{ leadData.officeZip ? leadData.zip : '&nbsp; ' }}</td>
                <td colspan="4">{{ leadData.officePhone ? leadData.officePhone : '&nbsp; ' }}</td>
              </tr>

              <tr class="title">
                <th colspan="4" class="noborderright">State</th>
                <th colspan="4" class="noborderright">Zip</th>
                <th colspan="4" class="noborderright">Office Phone</th>
              </tr>

              <tr class="textvalue">
                <td colspan="4">{{ leadData.supervisor ? leadData.supervisor : '&nbsp; ' }}</td>
                <td colspan="4">{{ leadData.grossIncome ? leadData.grossIncome : '&nbsp; ' }}</td>
                <td colspan="4">{{ leadData.otherIncome ? leadData.otherIncome : '&nbsp; ' }}</td>
              </tr>

              <tr class="title">
                <th colspan="4" class="noborderright">Supervisor</th>
                <th colspan="4" class="noborderright">Gross Monthly Income</th>
                <th colspan="4" class="noborderright">Other Monthly Income</th>
              </tr>


              <tr class="textvalue">
                <td colspan="4">{{ leadData.occupation ? leadData.occupation : '&nbsp; ' }}</td>
                <td colspan="4">{{ leadData.numberOfYears ? leadData.numberOfYears : '&nbsp; ' }}</td>
                <td colspan="4">
                  <span style="font-size:180%;"
                    v-if="leadData.coApplicant"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Co-Applicant
                </td>
              </tr>

              <tr class="title">
                <th colspan="4" class="noborderright">Occupation</th>
                <th colspan="4" class="noborderright">Number of Years at Present</th>
                <th colspan="4" class="noborderright">Co-Applicant</th>
              </tr>


            </tbody>


          </table>


          <table class="table table-bordered" v-if="leadData.reason == 'TO LEASE A VEHICLE'">

            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Trade-in Vehicle Information</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue">
                <td colspan="6">{{ leadData.tradeIn ? leadData.tradeIn : '&nbsp; ' }}</td>
                <td colspan="6">{{ leadData.tradeInStatus ? leadData.tradeInStatus : '&nbsp; ' }}</td>
              </tr>

              <tr class="title">
                <th colspan="6" class="noborderright">Vehicle</th>
                <th colspan="6" class="noborderright">Vehicle Status</th>
              </tr>

         

            </tbody>


            </table>

            <table class="table table-bordered" v-if="leadData.reason == 'TO LEASE A VEHICLE'">

              <thead class="thead-dark">
                <tr>
                  <th scope="col" colspan="12">Vehicle of Interest</th>
                </tr>
              </thead>
              <tbody class="text-center">
              <tr class="textvalue">
                <td colspan="4">{{ leadData.make ? leadData.make : '' }}</td>
                <td colspan="4">{{ leadData.model ? leadData.model : '' }}</td>
                <td colspan="4">{{ leadData.year ? leadData.year : '' }}</td>
              </tr>

              <tr class="title">
                <th colspan="4" class="noborderright">Make</th>
                <th colspan="4" class="noborderright">Model</th>
                <th colspan="4">Year</th>
              </tr>

              <tr class="textvalue">
                <td colspan="4">{{ leadData.trim ? leadData.trim : '&nbsp; ' }}</td>
                <td colspan="4">{{ leadData.mileageUnit ? leadData.mileageUnit : '&nbsp; ' }}</td>
                <td colspan="2">{{ leadData.fuelType ? leadData.fuelType : '&nbsp; ' }}</td>
                <td colspan="2">{{ leadData.transmission ? leadData.transmission : '&nbsp; ' }}</td>
              </tr>

              <tr class="title">
                <th colspan="4" class="noborderright">Trim</th>
                <th colspan="4" class="noborderright">Mileage & Unit</th>
                <th colspan="2" class="noborderright">Fuel Type</th>
                <th colspan="2">Transmission</th>
              </tr>


              <tr class="textvalue">
                <td colspan="12">{{ leadData.downPayment ? leadData.downPayment : '&nbsp; ' }}</td>
              </tr>

              <tr class="title">
                <th colspan="12" class="noborderright">Down Payment</th>
              </tr>


            </tbody>

              </table>



          <table class="table table-bordered"
            v-if="leadData.reason == 'CAR HUNTING' || leadData.reason == 'TO SELL A VEHICLE' || leadData.reason == 'VEHICLE ENQUIRY'">

            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Vehicle Details</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue">
                <td colspan="4">{{ leadData.make ? leadData.make : '' }}</td>
                <td colspan="4">{{ leadData.model ? leadData.model : '' }}</td>
                <td colspan="4">{{ leadData.year ? leadData.year : '' }}</td>
              </tr>

              <tr class="title">
                <th colspan="4" class="noborderright">Make</th>
                <th colspan="4" class="noborderright">Model</th>
                <th colspan="4">Year</th>
              </tr>

              <tr class="textvalue">
                <td colspan="4">{{ leadData.trim ? leadData.trim : '&nbsp; ' }}</td>
                <td colspan="4">{{ leadData.mileageUnit ? leadData.mileageUnit : '&nbsp; ' }}</td>
                <td colspan="2">{{ leadData.fuelType ? leadData.fuelType : '&nbsp; ' }}</td>
                <td colspan="2">{{ leadData.transmission ? leadData.transmission : '&nbsp; ' }}</td>
              </tr>

              <tr class="title">
                <th colspan="4" class="noborderright">Trim</th>
                <th colspan="4" class="noborderright">Mileage & Unit</th>
                <th colspan="2" class="noborderright">Fuel Type</th>
                <th colspan="2">Transmission</th>
              </tr>
            </tbody>


          </table>

          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Customer Message</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="title">
                <td colspan="12" style="height:250px; font-size:14px; text-align:left; vertical-align:top">
                  {{ leadData.message }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>

        <!-- <b-row v-if="leadData.status == 'B' || leadData.status == 'C' || leadData.status == 'D'">
          <b-col cols="12">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">User Notes</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="title">
                    <td colspan="12" style="height:250px; font-size:14px; text-align:left; vertical-align:top">
                      {{ leadData.note }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row> -->
      </b-col>

      <b-col cols="12" xl="3" md="4" class="invoice-actions ">
        <b-card v-if="leadData.status != 'J' && leadData.status != 'F' && leadData.status != 'P'">
          <b-button v-if="leadData.status != 'D' && $Can('lead_edit')" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary" class="mb-75" block :to="{ name: 'leads-edit', params: { id: leadData.id } }">
            Edit
          </b-button>

          <b-button v-show="$Can('lead_assign')" v-if="leadData.status != 'D'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" class="mb-75" block @click="assignLead">
            Assign
          </b-button>

          <b-button
            v-if="(leadData.status == 'B' || leadData.status == 'C' || leadData.status == 'E') && $Can('lead_edit')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-75" block @click="logResponse">
            Log a Response
          </b-button>

          <b-button v-if="(leadData.status == 'B' || leadData.status == 'C') && leadData.reason == 'TO SELL A VEHICLE'"
            v-show="$Can('purchase_quote_create')" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info"
            class="mb-75" block @click="purchaseModal">
            Create Purchase Quote
          </b-button>

          <b-button
            v-if="(leadData.status == 'B' || leadData.status == 'C') && (leadData.reason == 'CAR HUNTING' || leadData.reason == 'VEHICLE ENQUIRY')"
            v-show="$Can('sale_quote_create')" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" class="mb-75"
            block @click="saleModal">
            Create Sale Quote
          </b-button>

          <b-button v-if="(leadData.status == 'C' || leadData.status == 'E') && $Can('lead_edit')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mb-75" block @click="completeLead">
            Complete
          </b-button>

          <b-button v-if="(leadData.status == 'D') && $Can('lead_edit')" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success" class="mb-75" block @click="markWin">
            Mark Win
          </b-button>

          <b-button v-if="(leadData.status == 'D') && $Can('lead_edit')" v-ripple.400="'rgba(0, 207, 232, 1)'"
            variant="secondary" class="mb-75" block @click="markLost">
            Mark Lost
          </b-button>

          <b-button v-if="$Can('lead_edit')" v-ripple.400="'rgba(0, 207, 232, 1)'" variant="info" class="mb-75" block
            @click="markJunk">
            Junk
          </b-button>
        </b-card>

        <b-card>
          <div>
            <app-timeline>
              <app-timeline-item v-for="item in leadData.get_timeline" :key="item.id" :title="item.username"
                :subtitle="item.note" :icon="varicon(item.responseMethod)" :variant="varyant(item.status)"
                :time="item.responseDate + '&nbsp; ' + item.responseMethod" />
            </app-timeline>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="modal-response" cancel-variant="outline-secondary" ok-title="Save" @ok="handleOk" cancel-title="Close"
      :no-close-on-backdrop="true" centered title="Interaction Log">
      <validation-observer ref="responseRules">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-col v-if="id != null">
            <b-form-group class="mb-75" label="Notes" label-for="h-notes">
              <validation-provider name="Notes" #default="{ errors }" rules="required">
                <b-form-textarea v-uppercase v-model="interactionLogs.note" id="notes" placeholder="Notes" rows="3" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col v-if="id != null">
            <b-form-group class="mb-75" label="Response Date" label-for="h-rdate">
              <validation-provider name="Response Date" #default="{ errors }" rules="required">
                <flat-pickr id="responseDate" class="form-control" :config="{ dateFormat: 'd-m-Y' }"
                  placeholder="DD-MM-YYYY" v-model="interactionLogs.responseDate" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col v-if="id != null">
            <b-form-group class="mb-75" label="Response Method" label-for="h-rmethods">
              <validation-provider name="Response Method" #default="{ errors }" rules="required">
                <v-select v-model="interactionLogs.responseMethod" :taggable="true" :options="methods" label="rMethod"
                  :clearable="true"> </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal id="modal-assign" cancel-variant="outline-secondary" ok-title="Save" @ok="assignOk" cancel-title="Close"
      :no-close-on-backdrop="true" centered title="Assign to User">
      <b-form-group label="Users" label-for="users">
        <v-select v-model="leadData.userId" :taggable="true" :options="users" label="value" :value="leadData.userId"
          :reduce="(val) => val.userId" :clearable="false" />
      </b-form-group>
    </b-modal>

    <b-modal id="modal-sale" cancel-variant="outline-secondary" ok-title="Save" @ok="handleOk" cancel-title="Close"
      :no-close-on-backdrop="true" centered title="Create a Sale Quote">
      <validation-observer ref="saleRules">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-col cols="12">
            <b-form-group label="Reason*" label-for="h-reason">
              <v-select v-model="leadData.reason" :disabled="leadData.id > 0" :options="reasons" label="reason"
                :clearable="false"> </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Vehicles" label-for="h-vehicles">
              <validation-provider name="Vehicle" #default="{ errors }" rules="required">
                <v-select v-model="leadData.stockNumber" :options="vehicles" :disabled="leadData.status == 'E'"
                  :value="leadData.stockNumber" :reduce="(val) => val.stockNumber" label="value" :clearable="false">
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal id="modal-purchase" cancel-variant="outline-secondary" ok-title="Save" @ok="handleOk" cancel-title="Close"
      :no-close-on-backdrop="true" centered title="Create a Purchase Quote">
      <validation-observer ref="purchaseRules">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-col cols="12">
            <b-form-group label="Reason*" label-for="h-reason">
              <validation-provider name="Reason" #default="{ errors }" rules="required">
                <v-select v-model="leadData.reason" :disabled="leadData.id > 0" :options="reasons" label="reason"
                  :clearable="false"> </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Vehicles*" label-for="h-vehicles">
              <validation-provider name="Vehicle" #default="{ errors }" rules="required">
                <v-select v-model="leadData.stockNumber" :options="vehicles" :disabled="leadData.status == 'E'"
                  :value="leadData.stockNumber" :reduce="(val) => val.stockNumber" label="value" :clearable="false"
                  required> </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal id="modal-complete" cancel-variant="outline-secondary" ok-title="Save" @ok="handleOk" cancel-title="Close"
      :no-close-on-backdrop="true" centered title="Complete the Lead">
      <validation-observer ref="compRules">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-col cols="12">
            <b-form-group label="Reason*" label-for="h-reason">
              <v-select v-model="leadData.reason" :disabled="leadData.id > 0" :options="reasons" label="reason"
                :clearable="false"> </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="leadData.reason == 'VEHICLE ENQUIRY'">
            <b-form-group label="Vehicles" label-for="h-vehicles">
              <v-select v-model="leadData.stockNumber" :options="vehicles" :disabled="leadData.status == 'E'"
                :value="leadData.stockNumber" :reduce="(val) => val.stockNumber" label="value" :clearable="false">
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12"
            v-if="(leadData.reason == 'CAR HUNTING' || leadData.reason == 'TO SELL A VEHICLE') && leadData.id">
            <b-form-group label="Vehicles" label-for="h-vehicles">
              <v-select v-model="leadData.stockNumber" :options="vehicles" :disabled="leadData.status == 'E'"
                :value="leadData.stockNumber" :reduce="(val) => val.stockNumber" label="value" :clearable="false">
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Solution*" label-for="h-solution">
              <validation-provider name="Solution" #default="{ errors }" rules="required">
                <v-select v-model="leadData.solution" :taggable="true" :disabled="leadData.status == 'E'"
                  :options="solutions" label="solution" :clearable="true" required> </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col v-if="id != null" cols="12">
            <b-form-group label="Notes" label-for="h-notes">
              <b-form-textarea v-uppercase v-model="leadData.note" id="notes" placeholder="Notes" rows="4" />
            </b-form-group>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-overlay>
</template>

<script>
import { VBTooltip, BImg, BMediaAside, BContainer, BBadge, BOverlay, BRow, BCol, BCard, BModal, VBModal, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BForm, BFormInput, BFormGroup, BFormCheckbox } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import router from '@/router';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import leadStoreModule from './leadStoreModule';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import { required, email } from '@validations';
import { ref, onUnmounted, reactive } from '@vue/composition-api';

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,

    uppercase: {
      update(element) {
        element.value = element.value.toUpperCase();
      },
    },
  },

  setup() {
    const LEAD_APP_STORE_MODULE_NAME = 'lead';
    // Register module
    if (!store.hasModule(LEAD_APP_STORE_MODULE_NAME)) store.registerModule(LEAD_APP_STORE_MODULE_NAME, leadStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LEAD_APP_STORE_MODULE_NAME)) store.unregisterModule(LEAD_APP_STORE_MODULE_NAME);
    });
  },

  components: {
    VBTooltip,
    BImg,
    BMediaAside,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormCheckbox,
    BOverlay,
    BBadge,
    BModal,
    AppTimeline,
    AppTimelineItem,
    router,
    store,
    leadStoreModule,
    ToastificationContent,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormInput,
    BFormGroup,
    flatPickr,
  },

  data() {
    var leadDataModel = {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      method: null,
      message: null,
      note: null,
      quote: null,
      vehicle: null,
      solution: null,
      reason: null,
      status: null,
      user: null,
      userId: null,
      stockNumber: null,
    };
    var localUser = JSON.parse(localStorage.getItem('userData'));

    return {
      localUser,

      required,
      email,
      interactionLogs: { leadId: null, responseMethod: null, responseDate: null, note: null },
      users: [],
      formShow: true,
      leadData: leadDataModel,
      id: null,
      methods: ['PHONE', 'EMAIL', 'SMS', 'LETTER'],
      reasons: ['GENERAL ENQUIRY', 'VEHICLE ENQUIRY', 'CAR HUNTING', 'TO SELL A VEHICLE', 'TO LEASE A VEHICLE'],
      vehicles: [],
      solutions: ['SALE QUOTE CREATED', 'PURCHASE QUOTE CREATED', 'LEASE INCEPTION CREATED', 'PROVIDED INFORMATION'],
      sales: [],
      purchases: [],
    };
  },

  created() {
    this.formShow = true;
    this.getInventoryDrops();
  },

  methods: {
    checkValidity(component) {
      if (component == 'modal-complete') {
        this.$refs.compRules.validate().then((success) => {
          if (success) {
            this.completeOk();
            this.$nextTick(() => {
              this.$bvModal.hide('modal-complete');
            });
          } else {
            return;
          }
        });
      } else if (component == 'modal-purchase') {
        this.$refs.purchaseRules.validate().then((success) => {
          if (success) {
            this.createPurchase();
            this.$nextTick(() => {
              this.$bvModal.hide(component);
            });
          } else {
            return;
          }
        });
      } else if (component == 'modal-sale') {
        this.$refs.saleRules.validate().then((success) => {
          if (success) {
            this.createSale();
            this.$nextTick(() => {
              this.$bvModal.hide(component);
            });
          } else {
            return;
          }
        });
      } else if (component == 'modal-response') {
        this.$refs.responseRules.validate().then((success) => {
          if (success) {
            this.responseOk();
            this.$nextTick(() => {
              this.$bvModal.hide(component);
            });
          } else {
            return;
          }
        });
      }
    },
    // checkCompValidity() {
    //     this.$refs.compRules.validate().then((success) => {
    //    if(success){
    //        this.completeOk();
    //         this.$nextTick(() => {
    //         this.$bvModal.hide('modal-complete');
    //       });
    //        }
    //       else{return;}

    //     });
    // },

    //   checkSaleValidity() {
    //     this.$refs.saleRules.validate().then((success) => {
    //      if(success){
    //        this.createSale();
    //         this.$nextTick(() => {
    //         this.$bvModal.hide('modal-sale');
    //       });
    //        }
    //       else{return;}

    //     });

    // },

    resetModal() {
      this.nameState = null;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      this.handleSubmit(bvModalEvent.componentId);
    },
    handleSubmit(component) {
      // Exit when the form isn't valid

      this.checkValidity(component);
    },

    completeLead() {
      this.$bvModal.show('modal-complete');
    },

    completeOk() {
      this.formShow = true;
      this.leadData.status = 'D';
      store.dispatch('lead/updateLead', this.leadData).then((response) => {
        this.leadData.get_timeline = response.data.get_timeline;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful',
            text: '✔️ Lead has been completed',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        });
      });
      this.formShow = false;
    },



    changeStatus(status) {
      this.formShow = true;
      this.leadData.status = status;
      store
        .dispatch('lead/updateLead', this.leadData)
        .then((response) => {
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Please try again or report an issue to support',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          });
        });
    },

    markJunk() {
      this.formShow = true;
      store
        .dispatch('lead/junkLead', { id: this.leadData.id })
        .then((response) => {
          this.getByIdLead();

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Please try again or report an issue to support',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          });
        });
    },
    markLost() {
      this.formShow = true;
      store
        .dispatch('lead/lostLead', { id: this.leadData.id })
        .then((response) => {
          this.getByIdLead();

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Please try again or report an issue to support',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          });
        });
    },
    markWin() {
      this.formShow = true;
      store
        .dispatch('lead/winLead', { id: this.leadData.id })
        .then((response) => {
          this.getByIdLead();

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Please try again or report an issue to support',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          });
        });
    },


    logResponse() {
      this.$bvModal.show('modal-response');
    },

    responseOk() {
      this.formShow = true;
      this.interactionLogs.leadId = this.id;
      this.interactionLogs.status = this.leadData.status;

      store
        .dispatch('lead/interactionLog', this.interactionLogs)
        .then((response) => {
          this.formShow = false;
          this.interactionLogs = { leadId: null, responseDate: null, responseMethod: null, note: null };
          this.leadData.get_timeline.push(response.data);
          if (this.leadData.status == 'B' || this.leadData.status == 'A') {
            this.changeStatus('C');
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Interaction log has been saved',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Please try again or report an issue to support',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          });
        });
    },

    assignLead() {
      this.$bvModal.show('modal-assign');
    },

    assignOk() {
      this.formShow = true;

      store.dispatch('lead/updateAssignLead', this.leadData).then((response) => {
        // this.leadData.user = response.data.user;
        this.leadData = response.data;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful',
            text: '✔️ Lead has been updated',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        });

        this.formShow = false;
      });
    },

    createPurchase() {
      this.formShow = true;
      store
        .dispatch('lead/leadPurchaseQuoteCreate', this.leadData)
        .then((response) => {
          this.leadData = response.data;
          let confirmText = '✔️ Quote' + response.data.id + 'has been created';
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: confirmText,
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Please try again or report an issue to support',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          });
        });
    },

    saleModal() {
      this.$bvModal.show('modal-sale');
    },

    purchaseModal() {
      this.$bvModal.show('modal-purchase');
    },

    createSale() {
      this.formShow = true;
      if (this.leadData.stockNumber == null) {
        this.$swal({
          title: "There isn't a selected vehicle!",
          text: 'Please select a vehicle to create a quote.',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
        this.formShow = false;
      } else {
        store
          .dispatch('lead/leadSaleQuoteCreate', this.leadData)
          .then((response) => {
            this.leadData = response.data;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Quote has been created',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.formShow = false;
          })
          .catch((error) => {
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    dateFormat(val) {
      return moment(String(val)).format('DD-MM-YYYY HH:mm');
    },

    getByIdLead() {
      if (router.currentRoute.params.id) {
        this.id = router.currentRoute.params.id;
        store
          .dispatch('lead/getLeadById', { id: router.currentRoute.params.id })
          .then((response) => {
            this.leadData = response.data;
            this.formShow = false;
          })
          .catch((error) => {
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            router.push({ name: 'leads-list' });
          });
      } else {
        this.formShow = false;
      }
    },

    getInventoryDrops() {
      store
        .dispatch('lead/getInventoryDropDowns')
        .then((response) => {
          response.data.inventory.forEach((element) => {
            if (element.value != null) {
              this.vehicles.push({
                value: element.value,
                stockNumber: element.stockNumber,
              });
            }
          });
          this.getUsers();
        })
        .catch((error) => { });
    },
    getPurchase() {
      store
        .dispatch('lead/getAllPurchase', [])
        .then((res) => {
          res.data.data.data.forEach((element) => {
            this.purchases.push(String(element.id) + ' - ' + element.title);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSale() {
      store
        .dispatch('lead/getAllSale', [])
        .then((res) => {
          res.data.data.data.forEach((element) => {
            this.sales.push(String(element.id) + ' - ' + element.title);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUsers() {
      store
        .dispatch('lead/fetchUsers', [])
        .then((res) => {
          res.data.forEach((element) => {
            if (element != null) {
              this.users.push({
                userId: Number(element.id),
                value: String(element.name + ' ' + element.surname),
              });
            }
          });
          this.users.push({
            userId: Number(0),
            value: 'All Users',
          });

          this.users = this.users.reverse();

          if (!this.$Can('lead_assign')) {
            this.leadData.userId = this.localUser.id;
          }

          this.getByIdLead();
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            title: 'Error!',
            text: 'User list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },

    varicon(val) {
      if (val == 'SMS') {
        return 'MessageSquareIcon';
      } else if (val == 'PHONE') {
        return 'PhoneCallIcon';
      } else if (val == 'EMAIL') {
        return 'MailIcon';
      } else if (val == 'LETTER') {
        return 'FileTextIcon';
      } else {
        return 'InboxIcon';
      }
    },

    varyant(val) {
      if (val == 'A') {
        return 'secondary';
      } else if (val == 'B') {
        return 'warning';
      } else if (val == 'C') {
        return 'primary';
      } else if (val == 'D') {
        return 'success';
      } else if (val == 'E') {
        return 'info';
      }
      else if (val == 'F') {
        return 'success';
      } else if (val == 'P') {
        return 'secondary';
      } else if (val == 'J') {
        return 'junk';
      }
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.textlongvalue {
  line-height: 1.84;
}

.title {
  font-size: 12px;
}

.textproduct {
  font-size: 12px;
}

.noborderright {
  border-right: hidden !important;
}

.valueinput {
  font-size: 16px;
}
</style>
